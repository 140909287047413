export const Button = {
  baseStyle: {
    borderRadius: 0,
  },
  variants: {
    pink: {
      bgColor: "pink.500",
      color: "white",
      px: 6,
    },
  },
}
